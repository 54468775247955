#contact {
	background-color: @gray-darkest;
	.section-heading {
		color: white;
	}
	.form-group {
		margin-bottom: 25px;
		input, textarea {
			padding: 20px;
		}
		input.form-control {
			height: auto;
		}
		textarea.form-control {
			height: 236px;
			resize: vertical;
		}
	}
	.form-control:focus {
		border-color: @brand-primary;
		box-shadow: none;
	}
	::-webkit-input-placeholder {
		.heading-font;
		font-weight: 700;
		color: @placeholder-text;
	}
	:-moz-placeholder {
		.heading-font;
		font-weight: 700;
		color: @placeholder-text;
	}
	::-moz-placeholder {
		.heading-font;
		font-weight: 700;
		color: @placeholder-text;
	}
	:-ms-input-placeholder {
		.heading-font;
		font-weight: 700;
		color: @placeholder-text;
	}

	.help-block {
		margin: 40px 0 15px;
		color: #d1d1d1;
	}

	.unhappyMessage {
		display: block;
		color: @brand-danger;
		margin: 6px 0 12px;
		font-size: 15px;
	}
}

.glyphicon-spinner {
	animation: spin 1s infinite linear;
	margin-right: 7px;
	display: none;

	.is-loading & {
		display: inline-block;
	}
}

@keyframes spin {
		from { transform: scale(1) rotate(0deg);}
		to { transform: scale(1) rotate(360deg);}
}
