// Font Selections

.serif-font() {
	font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.script-font() {
	font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}

.body-font() {
	font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.heading-font() {
	font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
}
