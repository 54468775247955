.navbar-brand img {

	width: 219px;
	height: 28px;

	transform: translateZ(0);
	transition-property: width, height;
	transition-duration: .3s;
	transition-timing-function: ease;

	@media (min-width: 992px) {
		width: 437px;
		height: 56px;

		.navbar-shrink & {
			width: 219px;
			height: 28px;
		}
	}
}

// Custom Navigation Bar
.navbar-default {
	background-color: @gray-darkest;
	border-color: transparent;
	.navbar-brand {
		color: @brand-primary;
		.script-font;
		&:hover, &:focus, &:active, &.active {
			color: darken(@brand-primary, 10%);
		}
	}
	.navbar-collapse {
		border-color: fade(white, 2%);
	}
	.navbar-toggle {
		background-color: @brand-primary;
		border-color: @brand-primary;
		.icon-bar {
			background-color: white;
		}
		&:hover,
		&:focus {
			background-color: @brand-primary;
		}
	}
	.nav {
		li {
			a {
				.heading-font;
				font-weight: 400;
				letter-spacing: 1px;
				color: white;
				&:hover, &:focus {
					color: @brand-primary;
					outline: none;
				}
			}
		}
	}
	.navbar-nav > .active > a {
		border-radius: 0;
		color: white;
		background-color: @brand-primary;
	}
	.navbar-nav > .active > a:hover,
	.navbar-nav > .active > a:focus {
		color: white;
		background-color: darken(@brand-primary, 10%);
	}
}


@media (min-width: 768px) {
	.navbar-default {
		//background-color: transparent;
		padding: 25px 0;
		transition: padding 0.3s;
		border: none;
		.navbar-brand {
			font-size: 2em;
			transition: all 0.3s;
		}
		.navbar-nav > .active > a {
			border-radius: 3px;
		}
	}

	.navbar-default.navbar-shrink {
		background-color: @gray-darkest;
		padding: 10px 0;
		.navbar-brand {
			font-size: 1.5em;
		}
	}
}
