html,
body {
	font-size: 100%;
	width: 100%;
	overflow-x: hidden;
}
body {
	overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
	.body-font();
	background: transparent url('../img/bg.png') repeat left top;
	box-sizing: border-box;
	webkit-tap-highlight-color: @brand-primary;
}

.text-muted {
	color: @gray;
}

.text-primary {
	color: @brand-primary;
}

.text-danger {
	color: @brand-danger;
}

p {
	font-size: 14px;
	line-height: 1.75;
}

p.large {
	font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
	outline: none;
}

a {
	color: @brand-primary;
}

a:hover,
a:focus,
a:active,
a.active {
	color: darken(@brand-primary, 10%);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	.heading-font;
	font-weight: 700;
}

.img-centered {
	margin: 0 auto;
}

.has-box-shadow {
	margin-bottom: .5rem;
	box-shadow:
	    0px 0px 1px 1px rgba(0, 0, 0, 0.25),
			0px 0px 3px 2px rgba(0, 0, 0, 0.095),
	    0px 0px 6px 1px rgba(0, 0, 0, 0.075);
}

section,
aside {
	padding-bottom: 20px;

	@media (min-width: 768px) {
		padding: 60px 0;
	}
}


.bg-light-gray {
	background-color: @gray-lighter;
}

.bg-darkest-gray {
	background-color: @gray-darkest;
}

// Restyled Primary Buttons
.btn-primary {
	.button-variant(white; @brand-primary; @brand-primary);
	.heading-font;
	font-weight: 700;
}

.btn-xl {
	.button-variant(white; @brand-primary; @brand-primary);
	.heading-font;
	font-weight: 700;
	border-radius: 3px;
	font-size: 18px;
	padding: 20px 40px;
}

section {
	padding: 100px 0;
}
h2.section-heading {
	font-size: 40px;
	margin-top: 0;
	margin-bottom: 15px;
}
h3.section-subheading {
	font-size: 16px;
	.serif-font;
	text-transform: none;
	font-style: italic;
	font-weight: 400;
	margin-bottom: 75px;
}

// Services Section
.service-heading {
	margin: 15px 0;
	text-transform: none;
}

// Timeline
.timeline {
	list-style: none;
	padding: 0;
	position: relative;
	margin-bottom: 20px;
	&:before {
		top: 0;
		bottom: 0;
		position: absolute;
		content: "";
		width: 2px;
		background-color: #f1f1f1;
		left: 40px;
		margin-left: -1.5px;
	}
	> li {
		margin-bottom: 50px;
		position: relative;
		min-height: 80px;
		&:before, &:after {
			content: "";
			display: table;
		}
		&:after {
			clear: both;
		}
		.timeline-panel {
			width: 100%;
			float: right;
			padding: 20px 0 0 100px;
			position: relative;
			text-align: left;
			&:before {
				border-left-width: 0;
				border-right-width: 15px;
				left: -15px;
				right: auto;
			}
			&:after {
				border-left-width: 0;
				border-right-width: 14px;
				left: -14px;
				right: auto;
			}
		}
		.timeline-image {
			left: 0;
			margin-left: 0;
			width: 80px;
			height: 80px;
			position: absolute;
			z-index: 100;
			background-color: @brand-primary;
			color: white;
			border-radius: 100%;
			border: 7px solid #f1f1f1;
			text-align: center;
			h4 {
				font-size: 10px;
				margin-top: 12px;
				line-height: 14px;
			}
		}
		&.timeline-inverted > .timeline-panel {
			float: right;
			text-align: left;
			&:before {
				border-left-width: 0;
				border-right-width: 15px;
				left: -15px;
				right: auto;
			}
			&:after {
				border-left-width: 0;
				border-right-width: 14px;
				left: -14px;
				right: auto;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.timeline-heading {
		h4 {
			margin-top: 0;
			color: inherit;
			&.subheading {
				text-transform: none;
			}
		}
	}
	.timeline-body {
		> p, > ul {
			margin-bottom: 0;
		}
	}
}

@media (min-width: 768px) {
	.timeline {

		margin-bottom: 40px;
		&:before {
			left: 50%;
		}
		> li {
			margin-bottom: 100px;
			min-height: 100px;
			.timeline-panel {
				width: 41%;
				float: left;
				padding: 30px 30px 0 0;
				text-align: right;
			}
			.timeline-image {
				width: 100px;
				height: 100px;
				left: 50%;
				margin-left: -50px;
				h4 {
					font-size: 13px;
					margin-top: 16px;
					line-height: 18px;
				}
			}
			&.timeline-inverted > .timeline-panel {
				float: right;
				text-align: left;
				padding: 30px 0 0 30px;
			}
		}
	}
}

@media (min-width: 992px) {
	.timeline {
		margin-bottom: 50px;
		> li {
			min-height: 150px;
			.timeline-panel {
				padding: 40px 40px 0 0;
			}
			.timeline-image {
				width: 150px;
				height: 150px;
				margin-left: -75px;
				h4 {
					font-size: 18px;
					margin-top: 30px;
					line-height: 26px;
				}
			}
			&.timeline-inverted > .timeline-panel {
				padding: 40px 0 0 40px;
			}
		}
	}
}

@media (min-width: 1200px) {
	.timeline {
		> li {
			min-height: 170px;
			.timeline-panel {
				padding: 50px 50px 0 0;
			}
			.timeline-image {
				width: 170px;
				height: 170px;
				margin-left: -85px;
				h4 {
					margin-top: 40px;
				}
			}
			&.timeline-inverted > .timeline-panel {
				padding: 50px 0 0 50px;
			}
		}
	}
}

// Team Section
.team-member {
	text-align: center;
	margin-bottom: 50px;
	img {
		margin: 0 auto;
		border: 7px solid white;
	}
	h4 {
		margin-top: 25px;
		margin-bottom: 0;
		text-transform: none;
	}
	p {
		margin-top: 0;
	}
}

// Footer
footer {
	padding: 25px 0;
	text-align: center;
	span.copyright {
		line-height: 40px;
		.heading-font;
		text-transform: none;
	}
	ul.quicklinks {
		margin-bottom: 0;
		line-height: 40px;
		.heading-font;
		text-transform: none;
	}
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
	outline: none;
}



// Highlight Color Customization
::-moz-selection {
	text-shadow: none;
	background: @brand-primary;
}

::selection {
	text-shadow: none;
	background: @brand-primary;
}

img::selection {
	background: transparent;
}

img::-moz-selection {
	background: transparent;
}


.copyright-notice {
	margin-top: 20px;
	margin-bottom: 30px;
	a {
		font-size: 11px;
		font-weight: normal;
		display: inline-block;
	}
}

.imprint footer {
  background-color: @gray-darkest;
  color: #fff;

  @media (min-height: 700px) {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
