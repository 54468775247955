.client {
	margin: 0 0 15px;
	padding: 10px 10px 100px;
	width: 100%;
	background-color: #fff;
	&:extend(.has-box-shadow);
	position: relative;

	img {
		margin: 8px auto;
		z-index: 2;
	}
	&-link {
		display: block;
		max-width: 400px;
		margin: 0 auto;
		height: 46%;
	}
	&-caption {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		max-width: 100%;
		text-align: center;
		padding: 50px 25px 25px;
		h4 {
			text-transform: none;
			margin: 0;
		}
		p {
			.serif-font;
			font-style: italic;
			font-size: 16px;
			margin: 0;
		}
	}

}
