header {
	text-align: center;
	color: @gray;

	padding: 50px 0 30px;

	.intro-text {
		padding-top: 70px;
		padding-bottom: 25px;
		img {
			display: block;
			width: 80%;
			margin: 0 auto;
		}
		.intro-lead-in {
			.serif-font;
			font-style: italic;
			font-size: 22px;
			line-height: 22px;
			margin-bottom: 25px;
		}
		.intro-heading {
			.heading-font;
			font-weight: 700;
			font-size: 50px;
			line-height: 50px;
			margin-bottom: 25px;
		}
	}
}

@media (min-width: 768px) {

	header {

		padding: 100px 0 60px;

		.intro-text {
			padding-top: 150px;
			padding-bottom: 100px;
			.intro-lead-in {
				.serif-font;
				font-style: italic;
				font-size: 40px;
				line-height: 40px;
				margin-bottom: 25px;
			}
			.intro-heading {
				.heading-font;
				font-weight: 700;
				font-size: 75px;
				line-height: 75px;
				margin-bottom: 50px;
			}
		}
	}
}
